import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import bg from "../images/bg.jpg";
import logo from "../images/logo.png";
import "../Admin/style.css"
import axios from "axios";
import { api_url } from "../constants/base_url";
import { useMutation } from "@tanstack/react-query";
import { loginSchema } from '../Validations/auth/login.schema';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import AuthForm from "../components/AuthForm/AuthForm";
import ROLES from '../constants/roles';
import { notifyError, notifySuccess } from "../App";
import { TiHome } from "react-icons/ti";


export default function Login() {
  const navigate = useNavigate()
  const schema = loginSchema();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const { mutate, isError, error, isLoading } = useMutation({
    mutationFn: (data) => {
      return axios.post(`${api_url}auth/login`, data, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
      })
        .catch(err => {
          console.error("Axios network error:", err?.response?.data?.message);
          if (err?.response?.data?.message === "Invalid credentials") {
            notifyError("Email or password is incorrect")
          }

          throw err;

        });
    },
    onSuccess: (data) => {
      console.log("Logged in successfully", data?.data?.data);

      window.localStorage.setItem("accessToken", data?.data?.data.access_token);
      window.localStorage.setItem("currentUser", JSON.stringify(data?.data?.data?.user));
      notifySuccess("Logged in successfully");
      switch (data?.data?.data?.user?.roles[0].name) {
        case ROLES.ADMIN:
          navigate("/admin");
          break;
        case ROLES.USER:
          navigate("/home");
          break;
        default:
          break;
      }
    },
    onError: (error) => {
      console.log("Login failed:", error);
    },
  });

  const onSubmit = (data) => {
    console.log("form data: ", data);
    mutate(data);
  };

  return (
    <div className="login-page" style={{ backgroundImage: `url(${bg})` }}>
      <button
        className="button-primary"
        style={{
          position: "absolute",
          top: "0",
          right: "0",
          margin: "20px",
          display: "flex",
          alignItems: "center",
          gap: "10px"
        }}
        onClick={() => navigate("/home")}
      >
        <TiHome />
        Home
      </button>
      <div className="login-from-wrap">
        <AuthForm onSubmit={handleSubmit(onSubmit)}>
          <h1 className="site-title">
            <Link to="#">
              <img src={logo} alt="logo" />
            </Link>
          </h1>

          <AuthForm.TextController
            id="email"
            label="Email"
            errors={errors}
            register={register}
            name='email'
          />

          <AuthForm.TextController
            id="password"
            label="Password"
            type="password"
            errors={errors}
            register={register}
            name='password'
          />

          <AuthForm.ButtonController isLoading={isLoading} type="submit">Login</AuthForm.ButtonController>

          {/* <Link to="/admin/login/forgot" className="for-pass">
            Forgot Password?
          </Link> */}
          <p className="swap-login-signup">
            Don't have an account? <Link to="/signup">Register</Link>.
          </p>
        </AuthForm>
      </div>
    </div>
  );
}
