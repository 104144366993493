// HomeSliderSection.js
import React from "react";
import SliderItem from "./slideritem";
import imageslide1 from "../../images/slider-banner-1.jpg";
import imageslide2 from "../../images/imgslider2.jpg";
// import imageslide2 from "../../images/slider-banner-2.jpg";
import Slider from "react-slick";
function HomeSliderSection() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <>
      <section className="home-slider-section">
        <Slider {...settings} className="home-slider">
          <SliderItem
            backgroundImage={imageslide2}
            title="TRAVELLING AROUND THE WORLD"
          />
          <SliderItem
            backgroundImage={imageslide1}
            title="TRAVELLING AROUND THE WORLD"
          />
        </Slider>
      </section>
    </>
  );
}

export default HomeSliderSection;
