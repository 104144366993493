import React, { useEffect } from 'react'
import innerBannerImage from "../images/inner-banner.jpg"
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import img30 from "../images/img30.jpg"
import img21 from "../images/img21.jpg";
import img17 from "../images/camel-trip.jpg";
import img18 from "../images/img18.jpg";
import img19 from "../images/img19.jpg";
import img20 from "../images/img20.jpg"
import img22 from "../images/img22.jpg"
import userImg from "../images/user-img.png"
import SocialMadia from '../components/socialMadia';
import PostThumb from '../components/postThumb';
import WidgetSocial from '../components/widgetSocial';
import { useQuery } from '@tanstack/react-query';
import { api_url } from '../constants/base_url';
import axios from 'axios';
import Loading from '../components/shared/Loading/Loading';
import RecentPost from '../components/RecentPost/RecentPost';
import BlogSubcards from '../components/BlogSubcards/BlogSubcards';



const BlogSingle = () => {
    const navigate = useNavigate();
    const { blogId } = useParams();
    const location = useLocation();
    const language = location.state?.language;

    useEffect(() => {
        if (!language) {
            navigate('/blog');
        }
    }, []);


    const { data, error, isLoading, isError, refetch } = useQuery({
        queryKey: ['blogCard'],
        queryFn: () =>
            axios.get(`${api_url}blog/show?Blogid=${blogId}&language=${language}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            }),
        onSuccess: (response) => {
            console.log("Data fetched successfully:", response.data);
        },
        onError: (err) => {
            console.error("Error fetching data:", err);
        }
    });

    console.log("current blog data:", data?.data?.data)


    return (
        <main id="content" className="site-main">
            <section className="inner-banner-wrap">
                <div className="inner-baner-container" style={{ backgroundImage: `url(${innerBannerImage})` }}>
                    <div className="container">
                        <div className="inner-banner-content">
                            <h1 className="inner-title">{data?.data?.data?.blog?.title}</h1>
                            <div className="entry-meta">
                                <span className="byline">
                                    <a href="#">Demoteam</a>
                                </span>
                                {/* <span className="posted-on">
                                    <a href="#">August 17, 2021</a>
                                </span> */}
                                <span className="comments-link">
                                    <a href="#">No Comments</a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="inner-shape"></div>
            </section>

            <div className="single-post-section">
                <div className="single-post-inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 primary right-sidebar">
                                <figure className="feature-image">
                                    <img src={img30} alt="" />
                                </figure>
                                <article className="single-content-wrap">
                                    {isLoading ? <Loading /> :
                                        <>
                                            <h3>{data?.data?.data?.blog?.title}</h3>
                                            <p>{data?.data?.data?.language?.description || ""}</p>
                                            <blockquote>
                                                <p>Sagittis perferendis? Leo nobis irure egestas excepturi...</p>
                                            </blockquote>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit...</p>

                                        </>
                                    }
                                </article>
                                <div className="meta-wrap">
                                    <div className="tag-links">
                                        <a href="#">Destination</a>, <a href="#">hiking</a>, <a href="#">Travel Dairies</a>
                                    </div>
                                </div>
                                <div class="post-socail-wrap">
                                    <div class="social-icon-wrap">
                                        <WidgetSocial
                                            type="facebook"
                                            name="Facebook"
                                            href={"https://www.facebook.com/albumtravelegypt?mibextid=ZbWKwL"} >
                                            <i class="icon fab fa-facebook-f"></i>
                                        </WidgetSocial>

                                        <WidgetSocial
                                            type="pinterest"
                                            name="Pinterest"
                                            href={"https://www.pinterest.com/Albumtravel/"}
                                        >
                                            <i class="icon fab fa-pinterest"></i>
                                        </WidgetSocial>

                                        <WidgetSocial
                                            type="whatsapp"
                                            name="Whatsapp"
                                            href={"https://wa.me/+201033973047"}>
                                            <i class="icon fab fa-whatsapp"></i>
                                        </WidgetSocial>

                                        <WidgetSocial
                                            type="tiktok"
                                            name="TikTok"
                                            href={"https://wa.me/+201033973047"}>
                                            <svg className="icon fab fa-tiktok" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tiktok" viewBox="0 0 16 18">
                                                <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z" />
                                            </svg>
                                        </WidgetSocial>

                                        <WidgetSocial
                                            type="instagram"
                                            name="Instagram"
                                            href={"https://www.instagram.com/albumtravelegypt?igsh=MWtmamk2aGRpMTVqYg=="}>
                                            <i className="icon fab fa-instagram" aria-hidden="true"></i>
                                        </WidgetSocial>

                                        <WidgetSocial
                                            type="tripadvisor"
                                            name="Tripadvisor"
                                            href={"https://www.tripadvisor.com/Profile/albumtravel"}>
                                            <i class="icon fab fa-tripadvisor" aria-hidden="true"></i>
                                        </WidgetSocial>

                                        <WidgetSocial
                                            type="trustpilot"
                                            name="Trustpilot"
                                            href={"https://www.trustpilot.com/review/albumtravel.com"}>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="20px" height="20px" viewBox="0 0 25 25" role="img" fill="#ffffff"><title>Trustpilot icon</title><path d="M12,17.964l5.214-1.321l2.179,6.714L12,17.964z M24,9.286h-9.179L12,0.643L9.179,9.286 H0l7.429,5.357l-2.821,8.643l7.429-5.357l4.571-3.286L24,9.286L24,9.286L24,9.286L24,9.286z" /></svg>

                                        </WidgetSocial>
                                    </div>
                                </div>
                                {/* <div class="author-wrap">
                                    <div class="author-thumb">
                                        <img src={userImg} alt="" />
                                    </div>
                                    <div class="author-content">
                                        <h3 class="author-name">Demoteam</h3>
                                        <p>Anim eligendi error magnis. Pretium fugiat cubilia ullamcorper adipisci, lobortis repellendus sit culpa maiores!</p>
                                        <a href="#" class="button-text">VIEW ALL POSTS &gt; </a>
                                    </div>
                                </div>
                                <div class="comment-area">
                                    <h2 class="comment-title">3 Comments</h2>
                                    <div class="comment-area-inner">
                                        <ol>
                                            <li>
                                                <figure class="comment-thumb">
                                                    <img src={img20} alt="" />
                                                </figure>
                                                <div class="comment-content">
                                                    <div class="comment-header">
                                                        <h5 class="author-name">Tom Sawyer</h5>
                                                        <span class="post-on">Jana 10 2020</span>
                                                    </div>
                                                    <p>Officia amet posuere voluptates, mollit montes eaque accusamus laboriosam quisque cupidatat dolor pariatur, pariatur auctor.</p>
                                                    <a href="#" class="reply"><i class="fas fa-reply"></i>Reply</a>
                                                </div>
                                            </li>
                                            <li>
                                                <ol>
                                                    <li>
                                                        <figure class="comment-thumb">
                                                            <img src={img21} alt="" />
                                                        </figure>
                                                        <div class="comment-content">
                                                            <div class="comment-header">
                                                                <h5 class="author-name">John Doe</h5>
                                                                <span class="post-on">Jana 10 2020</span>
                                                            </div>
                                                            <p>Officia amet posuere voluptates, mollit montes eaque accusamus laboriosam quisque cupidatat dolor pariatur, pariatur auctor.</p>
                                                            <a href="#" class="reply"><i class="fas fa-reply"></i>Reply</a>
                                                        </div>
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                        <ol>
                                            <li>
                                                <figure class="comment-thumb">
                                                    <img src={img22} alt="" />
                                                </figure>
                                                <div class="comment-content">
                                                    <div class="comment-header">
                                                        <h5 class="author-name">Jaan Smith</h5>
                                                        <span class="post-on">Jana 10 2020</span>
                                                    </div>
                                                    <p>Officia amet posuere voluptates, mollit montes eaque accusamus laboriosam quisque cupidatat dolor pariatur, pariatur auctor.</p>
                                                    <a href="#" class="reply"><i class="fas fa-reply"></i>Reply</a>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                    <div class="comment-form-wrap">
                                        <h2 class="comment-title">Leave a Reply</h2>
                                        <p>Your email address will not be published. Required fields are marked *</p>
                                        <form class="comment-form">
                                            <p class="full-width">
                                                <label>Comment</label>
                                                <textarea rows="9"></textarea>
                                            </p>
                                            <p>
                                                <label>Name *</label>
                                                <input type="text" name="name" />
                                            </p>
                                            <p>
                                                <label>Email *</label>
                                                <input type="email" name="email" />
                                            </p>
                                            <p>
                                                <label>Website</label>
                                                <input type="text" name="web" />
                                            </p>
                                            <p class="full-width">
                                                <input type="submit" name="submit" value="Post comment" />
                                            </p>
                                        </form>
                                    </div>
                                    <div class="post-navigation">
                                        <div class="nav-prev">
                                            <a href="#">
                                                <span class="nav-label">Previous Reading</span>
                                                <span class="nav-title">Deleniti illum culpa sodales cubilia.</span>
                                            </a>
                                        </div>
                                        <div class="nav-next">
                                            <a href="#">
                                                <span class="nav-label">Next Reading</span>
                                                <span class="nav-title">Deleniti illum culpa sodales cubilia.</span>
                                            </a>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div class="col-lg-4 secondary">
                                <div class="sidebar">
                                    <aside>
                                        <BlogSubcards blogId={blogId} language={language} />
                                    </aside>
                                    {/* <aside class="widget author_widget">
                                        <h3 class="widget-title">ABOUT AUTHOR</h3>
                                        <div class="widget-content text-center">
                                            <div class="profile">
                                                <figure class="avatar">
                                                    <a href="#">
                                                        <img src={img21} alt="" />
                                                    </a>
                                                </figure>
                                                <div class="text-content">
                                                    <div class="name-title">
                                                        <h3>
                                                            <a href="https://demo.bosathemes.com/bosa/photography/james-watson/">
                                                                Album Travel
                                                            </a>
                                                        </h3>
                                                    </div>
                                                    <p>
                                                        We are committed to curate and flawlessly execute
                                                        travel experiences that allow travelers to explore
                                                        the world with ease and create memories that last
                                                        a lifetime.
                                                    </p>
                                                </div>
                                                <SocialMadia />
                                            </div>
                                        </div>
                                    </aside> */}
                                    <RecentPost />
                                    <aside class="widget widget_social">
                                        <h3 class="widget-title">Social share</h3>
                                        <div class="social-icon-wrap">

                                            <WidgetSocial
                                                type="facebook"
                                                name="Facebook"
                                                href={"https://www.facebook.com/albumtravelegypt?mibextid=ZbWKwL"} >
                                                <i class="icon fab fa-facebook-f"></i>
                                            </WidgetSocial>

                                            <WidgetSocial
                                                type="pinterest"
                                                name="Pinterest"
                                                href={"https://www.pinterest.com/Albumtravel/"}
                                            >
                                                <i class="icon fab fa-pinterest"></i>
                                            </WidgetSocial>

                                            <WidgetSocial
                                                type="whatsapp"
                                                name="Whatsapp"
                                                href={"https://wa.me/+201033973047"}>
                                                <i class="icon fab fa-whatsapp"></i>
                                            </WidgetSocial>

                                            <WidgetSocial
                                                type="tiktok"
                                                name="TikTok"
                                                href={"https://wa.me/+201033973047"}>
                                                <svg className="icon fab fa-tiktok" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tiktok" viewBox="0 0 16 18">
                                                    <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z" />
                                                </svg>
                                            </WidgetSocial>

                                            <WidgetSocial
                                                type="instagram"
                                                name="Instagram"
                                                href={"https://www.instagram.com/albumtravelegypt?igsh=MWtmamk2aGRpMTVqYg=="}>
                                                <i className="icon fab fa-instagram" aria-hidden="true"></i>
                                            </WidgetSocial>

                                            <WidgetSocial
                                                type="tripadvisor"
                                                name="Tripadvisor"
                                                href={"https://www.tripadvisor.com/Profile/albumtravel"}>
                                                <i class="icon fab fa-tripadvisor" aria-hidden="true"></i>
                                            </WidgetSocial>

                                            <WidgetSocial
                                                type="trustpilot"
                                                name="Trustpilot"
                                                href={"https://www.trustpilot.com/review/albumtravel.com"}>
                                                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="20px" height="20px" viewBox="0 0 25 25" role="img" fill="#ffffff"><title>Trustpilot icon</title><path d="M12,17.964l5.214-1.321l2.179,6.714L12,17.964z M24,9.286h-9.179L12,0.643L9.179,9.286 H0l7.429,5.357l-2.821,8.643l7.429-5.357l4.571-3.286L24,9.286L24,9.286L24,9.286L24,9.286z" /></svg>

                                            </WidgetSocial>

                                        </div>
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default BlogSingle