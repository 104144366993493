import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React from 'react'
import { api_url } from '../../constants/base_url';

const LangFilter = ({ setLanguage }) => {
    // const { data, error, isLoading, isError, refetch } = useQuery({
    //     queryKey: ['blogCard'],
    //     queryFn: () =>
    //         axios.get(`${api_url}blogLanguage`, {
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    //             },
    //         }),
    //     onSuccess: (response) => {
    //         console.log("All Languages:", response.data);
    //     },
    //     onError: (err) => {
    //         console.error("Error fetching data:", err);
    //     }
    // });

    const handleLanguageChange = (event) => {
        const selectedLanguage = event.target.value;
        setLanguage(selectedLanguage);
    };

    return (
        <select name="travel-destination" onChange={handleLanguageChange}>
            <option value="all" disabled selected>All Languages</option>
            <option value="ar" style={{color: "black"}}>ar</option>
            <option value="en" style={{color: "black"}}>en</option>
        </select>
    )
}

export default LangFilter