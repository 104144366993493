import React, { useState } from "react";
import style from "./TourDetails.module.css";
import { useParams } from "react-router-dom";
import { IoHourglassOutline } from "react-icons/io5";
import { IoEarth } from "react-icons/io5";
import { IoPeople } from "react-icons/io5";
import { IoCalendarOutline } from "react-icons/io5";

import img1 from "../../images/Luxor-Trips-in-Egypt.jpg";
import img2 from "../../images/Saqqara.jpg";
import img3 from "../../images/slider-banner-2.jpg";
import img4 from "../../images/karnak.jpeg";
import img5 from "../../images/img7.jpg";
import img6 from "../../images/camel-trip.jpg";
import img7 from "../../images/img13.jpg";
import img8 from "../../images/img2 - Copy.jpg";
import img9 from "../../images/gallery-6.jpg";

import Modal from "../../components/shared/Modal/Modal";
import ImagesSlider from "./../../components/slider/imageslider";
import Form from "../../components/shared/Form/Form";
import PricesCard from "../../components/TourDetailsComponents/PricesCard/PricesCard";
import ItineraryCard from "./../../components/TourDetailsComponents/ItineraryCard/ItineraryCard";
import { api_url } from "../../constants/base_url";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useForm } from "react-hook-form";

const images = [img1, img2, img3, img4, img5, img6];
const itineraries = [
  {
    title: "4 Days - Aswan / Luxor Every Friday",
    days: [
      {
        label: "Day 1: Embark Dahabiya",
        programDetails: {
          details: [
            "Embark in Aswan at 12:00 PM",
            "Visit Philae Temple",
            "Dinner on board",
          ],
          summary: "Sailing at 17:00 PM and overnight Herdiab Island.",
        },
        meals: "Lunch and Dinner",
        visits: "Aswan, Philae Temple",
      },
      {
        label: "Day 2: Kom Ombo",
        programDetails: {
          details: [
            "Breakfast on board and sailing to Kom Ombo",
            "Visit Kom Ombo Temple",
            "Sailing to Edfu and overnight",
          ],
          summary: "Overnight at Edfu.",
        },
        meals: "Breakfast, Lunch, and Dinner",
        visits: "Kom Ombo Temple",
      },
      {
        label: "Day 3: Sailing to Edfu",
        programDetails: {
          details: [
            "Enjoy early breakfast on board while sailing to Edfu",
            "Visit Edfu Temple",
            "Sailing to Esna and crossing Esna Lock while having Lunch",
          ],
          summary: "Overnight at Luxor.",
        },
        meals: "Breakfast, Lunch, and Dinner",
        visits: "Kom Ombo Temple",
      },
      {
        label: "Day 4: Luxor - Final Departure",
        programDetails: {
          details: [
            "Breakfast on board",
            "Disembark, then get transferred to visit Valley of the Kings and Karnak Temple",
            "Transfer to Luxor Airport for your final departure",
          ],
          summary: "",
        },
        meals: "Breakfast",
        visits: "Valley of the Kings, Karnak Temple",
      },
    ],
    NB: "Itineraries may be changed without prior notice due to navigational circumstances, maybe in sequences but not in visits",
  },
  {
    title: "5 Days - Luxor / Aswan Every Monday",
    days: [
      {
        label: "Day 1: Embark Dahabiya",
        programDetails: {
          details: [
            "Embark in Aswan at 12:00 PM",
            "Visit Philae Temple",
            "Dinner on board",
          ],
          summary: "Sailing at 17:00 PM and overnight Herdiab Island.",
        },
        meals: "Lunch and Dinner",
        visits: "Aswan, Philae Temple",
      },
      {
        label: "Day 2: Kom Ombo",
        programDetails: {
          details: [
            "Breakfast on board and sailing to Kom Ombo",
            "Visit Kom Ombo Temple",
            "Sailing to Edfu and overnight",
          ],
          summary: "Overnight at Edfu.",
        },
        meals: "Breakfast, Lunch, and Dinner",
        visits: "Kom Ombo Temple",
      },
      {
        label: "Day 3: Sailing to Edfu",
        programDetails: {
          details: [
            "Enjoy early breakfast on board while sailing to Edfu",
            "Visit Edfu Temple",
            "Sailing to Esna and crossing Esna Lock while having Lunch",
          ],
          summary: "Overnight at Luxor.",
        },
        meals: "Breakfast, Lunch, and Dinner",
        visits: "Kom Ombo Temple",
      },
      {
        label: "Day 4: Luxor - Final Departure",
        programDetails: {
          details: [
            "Breakfast on board",
            "Disembark, then get transferred to visit Valley of the Kings and Karnak Temple",
            "Transfer to Luxor Airport for your final departure",
          ],
          summary: "",
        },
        meals: "Breakfast",
        visits: "Valley of the Kings, Karnak Temple",
      },
    ],
    NB: "Itineraries may be changed without prior notice due to navigational circumstances, maybe in sequences but not in visits",
  },
  {
    title: "8 Days - Luxor / Luxor Every Monday",
    days: [
      {
        label: "Day 1: Embark Dahabiya",
        programDetails: {
          details: [
            "Embark in Aswan at 12:00 PM",
            "Visit Philae Temple",
            "Dinner on board",
          ],
          summary: "Sailing at 17:00 PM and overnight Herdiab Island.",
        },
        meals: "Lunch and Dinner",
        visits: "Aswan, Philae Temple",
      },
      {
        label: "Day 2: Kom Ombo",
        programDetails: {
          details: [
            "Breakfast on board and sailing to Kom Ombo",
            "Visit Kom Ombo Temple",
            "Sailing to Edfu and overnight",
          ],
          summary: "Overnight at Edfu.",
        },
        meals: "Breakfast, Lunch, and Dinner",
        visits: "Kom Ombo Temple",
      },
      {
        label: "Day 3: Sailing to Edfu",
        programDetails: {
          details: [
            "Enjoy early breakfast on board while sailing to Edfu",
            "Visit Edfu Temple",
            "Sailing to Esna and crossing Esna Lock while having Lunch",
          ],
          summary: "Overnight at Luxor.",
        },
        meals: "Breakfast, Lunch, and Dinner",
        visits: "Kom Ombo Temple",
      },
      {
        label: "Day 4: Luxor - Final Departure",
        programDetails: {
          details: [
            "Breakfast on board",
            "Disembark, then get transferred to visit Valley of the Kings and Karnak Temple",
            "Transfer to Luxor Airport for your final departure",
          ],
          summary: "",
        },
        meals: "Breakfast",
        visits: "Valley of the Kings, Karnak Temple",
      },
    ],
    NB: "Itineraries may be changed without prior notice due to navigational circumstances, maybe in sequences but not in visits",
  },
];
const prices = [
  {
    title: "4-Day Itinerary",
    iteration: [
      {
        start: "may",
        end: "august",
        details: [
          {
            label: "Per Person in Double Room",
            price: "US$ 2674",
          },
          {
            label: "Per Person in Single Room",
            price: "US$ 4134",
          },
        ],
      },
      {
        start: "september",
        end: "april",
        details: [
          {
            label: "Per Person in Double Room",
            price: "US$ 3447",
          },
          {
            label: "Per Person in Single Room",
            price: "US$ 5267",
          },
        ],
      },
    ],
    hotels: [
      {
        image: img7,
        name: "Asharanda Nebyt Dahabiya Cruise",
        link: "https://www.tripadvisor.com/Attraction_Review-g294201-d472084-Reviews-Nile_River-Cairo_Cairo_Governorate.html",
        rating: "100%",
      },
    ],
  },
  {
    title: "5-Day Itinerary",
    iteration: [
      {
        start: "may",
        end: "august",
        details: [
          {
            label: "Per Person in Double Room",
            price: "US$ 3542",
          },
          {
            label: "Per Person in Single Room",
            price: "US$ 5486",
          },
        ],
      },
      {
        start: "september",
        end: "april",
        details: [
          {
            label: "Per Person in Double Room",
            price: "US$ 3650",
          },
          {
            label: "Per Person in Single Room",
            price: "US$ 5594",
          },
        ],
      },
    ],
    hotels: [
      {
        image: img8,
        name: "Asharanda Nebyt Dahabiya Cruise",
        link: "https://www.tripadvisor.com/Attraction_Review-g294201-d472084-Reviews-Nile_River-Cairo_Cairo_Governorate.html",
        rating: "40%",
      },
    ],
  },
  {
    title: "8-Day Itinerary",
    iteration: [
      {
        start: "may",
        end: "august",
        details: [
          {
            label: "Per Person in Double Cabin",
            price: "US$ 6135",
          },
          {
            label: "Per Person in Double Suite",
            price: "US$ 8970",
          },
          {
            label: "Per Person in Single Cabin",
            price: "US$ 9535",
          },
        ],
      },
      {
        start: "september",
        end: "april",
        details: [
          {
            label: "Per Person in Double Cabin",
            price: "US$ 7905",
          },
          {
            label: "Per Person in Double Suite",
            price: "US$ 11450",
          },
          {
            label: "Per Person in Single Cabin",
            price: "US$ 12155",
          },
        ],
      },
    ],
    hotels: [
      {
        image: img9,
        name: "Asharanda Nebyt Dahabiya Cruise",
        link: "https://www.tripadvisor.com/Attraction_Review-g294201-d472084-Reviews-Nile_River-Cairo_Cairo_Governorate.html",
        rating: "60%",
      },
    ],
  },
];
const reviews = [
  {
    rating: "100%",
    title: "Family Trip",
    userName: "Max",
    date: "12/13/22",
    comment:
      "We had an amazing time aboard the dahabiya it gave us the perfect privacy for a family trip.",
  },
  {
    rating: "60%",
    title: "Crew was friendly & food was amazing",
    userName: "Prtrick B",
    date: "12/13/22",
    comment:
      "The crew was wonderfully friendly and accommodating and the food was amazing. We really enjoyed and we are glad we chose to sail on a dahabiya instead of big boats.",
  },
  {
    rating: "40%",
    title: "Amazing time aboard Neybt Dahabiya",
    userName: "Mary K",
    date: "12/13/22",
    comment:
      "We just completed a excellent five night cruise. We booked Nebyt Dahabiya from Luxor to Aswan. The boat far surpassed our expectations. It was extremely clean throughout with spacious.",
  },
  {
    rating: "20%",
    title: "First time on a dahabiya",
    userName: "Michelle",
    date: "12/13/22",
    comment:
      "We usually go on big boats and cruises. This was our first time travel aboard of a dahabiya and we LOVE IT! Highly Recommended it!",
  },
  {
    rating: "0%",
    title: "Dahabiya Cruise",
    userName: "Ella",
    date: "12/13/22",
    comment:
      "We recently enjoyed a 4-night cruise aboard the Neybt Dahabiya organized by Memphis Tour and what an incredible experience it was!",
  },
  {
    rating: "100%",
    title: "Well Organized",
    userName: "Malik",
    date: "12/13/22",
    comment: "We really enjoyed this wonderful itinerary. Very well organized.",
  },
];

const TourDetails = () => {
  const { tourId } = useParams();

  const [currentImage, setCurrentImage] = useState(images[0]);
  const [openImageSlider, setOpenImageSlider] = useState(false);
  const [tab, setTab] = useState(itineraries[0].title);
  const [currentItineraries, setCurrentItineraries] = useState(itineraries[0]);
  const [openBook, setOpenBook] = useState(false);
  const cruise = itineraries.map((itinerary) => itinerary.title);

  const { data, error, isLoading, isError } = useQuery({
    queryKey: ["tour", tourId], // same key
    queryFn: () =>
      axios.get(`${api_url}tour/${tourId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
  });

  return (
    <>
      <div className={style.banner}></div>

      <div className={style.container}>
        <h1>{data?.data?.data?.title}</h1>
        <div className={style.details}>
          <div className={style.leftSection}>
            <div className={style.images}>
              <div style={{ backgroundImage: `url(${currentImage})` }}></div>
              {images.slice(0, 2).map((image, index) => (
                <div
                  key={index}
                  className={currentImage === image && style.active}
                  style={{ backgroundImage: `url(${image})` }}
                  onClick={() => setCurrentImage(image)}
                ></div>
              ))}
              <div
                style={{ backgroundImage: `url(${images[2]})` }}
                onClick={() => {
                  setCurrentImage(images[2]);
                  setOpenImageSlider(true);
                }}
              >
                <p>+{images.length - 2} Photos</p>
              </div>
            </div>
            <div className={style.overview}>
              <h2>Overview</h2>
              <div>
                <OverviewCard
                  icon={<IoHourglassOutline />}
                  text="Schedule"
                  subText="4/5/8 Days"
                  details="3/4/7 Nights"
                />
                <OverviewCard
                  icon={<IoEarth />}
                  text="Countries"
                  subText="1 Country"
                  details="5 Cities"
                />
                <OverviewCard
                  icon={<IoPeople />}
                  text="Type"
                  subText="Small group tour"
                  details={`${data?.data?.data?.number_of_people} : Persons`}
                />
                <OverviewCard
                  icon={<IoCalendarOutline />}
                  text="Run"
                  subText="Monday from Luxor"
                  details="Friday from Aswan"
                />
              </div>
              <p>{data?.data?.data?.description}</p>
            </div>
          </div>
          <a
            href="#bookForm"
            className={`button-primary ${style.bookButton}`}
            onClick={() => setOpenBook(!openBook)}
          >
            Book Now
          </a>
          <div
            id="bookForm"
            className={style.bookingForm}
            style={{ display: openBook && "block" }}
          >
            <BookingForm title={"US$2674"} cruise={cruise} />
          </div>
        </div>
        <div className={style.includeExclude}>
          <div className={style.include}>
            <h3>Included</h3>
            <ul>
              <li>Meet and assist service upon arrival & departure</li>
              <li>
                Assistance of our personal during your stay and excursions
              </li>
              <li>All transfers by a modern air-conditioned deluxe vehicle</li>
              <li>
                Accommodation on board 5 star Dahabiya on full board basis
              </li>
              <li>Tea and Coffee during meals on board your Dahabiya</li>
              <li>Free Soft Drinks at Minibar</li>
              <li>All Dahabiya excursions as mentioned in the itinerary</li>
              <li>Entrance fees to all sights between Luxor and Aswan</li>
              <li>Egyptologist guide during your excursions</li>
              <li>All service charges and taxes</li>
            </ul>
          </div>
          <div className={style.exclude}>
            <h3>Exclude</h3>
            <ul>
              <li>Any extras not mentioned in the itinerary</li>
              <li>Tipping Kitty</li>
            </ul>
          </div>
        </div>
        <div className={style.nbSection}>
          <h3>N.B.:</h3>
          <ul>
            <li>
              Itineraries may be changed without prior notice due to
              navigational circumstances, the change will only affect the
              sequence of the visits, but not in visits themselves.
            </li>
            <li>
              The Nile Dahabiya departure is dependent on booking at least 2
              double cabins to guarantee sailing for 4 nights and 3 cabins for 3
              nights Dahabiya Cruise.
            </li>
            <li>
              The Nile Dahabiya departure is dependent on booking at least 1
              double suite to guarantee sailing for 7 nights Dahabiya Cruise.
            </li>
          </ul>
        </div>
        <div className={style.itinerary}>
          <h2>Itinerary</h2>
          <div className={style.tabs}>
            <ul>
              {itineraries.map((itinerary, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setTab(itinerary.title);
                    setCurrentItineraries(itinerary);
                  }}
                  className={tab === itinerary.title && style.active}
                >
                  {itinerary.title}
                </li>
              ))}
            </ul>
          </div>
          <div className={style.page}>
            <ItineraryCard itinerary={currentItineraries} />
          </div>
        </div>
        <div className={style.cruise}>
          <h2>Cruise</h2>
          <p>
            All the rooms and suites at Dahabiya Nile Cruise in egypt have been
            designed and styled to make you feel comfortable every moment you’re
            there.
          </p>
          <h4>Number of Rooms</h4>
          <ul>
            <li>6 cabins with twin or large beds</li>
            <li>1 suit with a private terrace</li>
          </ul>
        </div>
        <div className={style.cabin}>
          <h2>Cabin</h2>
          <ul>
            <li>In-room private safe</li>
            <li>Large windows with a panoramic view</li>
            <li>Fluffy and modern duvets</li>
            <li>Hairdryer</li>
            <li>Elegant fine dining room and lounge</li>
            <li>Electronic fire and smoke detection</li>
            <li>Wireless internet capabilities</li>
            <li>Chaise-longue chairs on the sun deck</li>
            <li>Soft Drinks in the minibar </li>
            <li>Free Wifi in The room </li>
          </ul>
        </div>
        <div className={style.prices}>
          <h2>Prices</h2>
          <div className={style.PricesCardsContainer}>
            {prices.map((priceCard) => (
              <PricesCard priceCard={priceCard} />
            ))}
          </div>
        </div>
        <div className={style.reviews}>
          <h2>Reviews</h2>
          <ReviewsSection reviews={reviews} />
        </div>
      </div>

      {openImageSlider && (
        <Modal isAppear onClose={() => setOpenImageSlider(false)}>
          <ImagesSlider images={images} />
        </Modal>
      )}
    </>
  );
};

export default TourDetails;

export const BookingForm = ({ title, cruise }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      Email: "",
      Code: "",
      name: "",
      content: "",
    },
  });
  return (
    <Form title={`From ${title}`}>
      <Form.TextController
        placeholder="Name"
        selectPlaceholder="Title"
        options={["Mr.", "Ms.", "Mrs."]}
        register={register}
        registername="name"
      />
      <Form.TextController
        placeholder="Email"
        register={register}
        registername="Email"
      />
      {cruise && (
        <Form.SelectController placeholder="Select cruise" options={cruise} />
      )}
      <Form.SelectController
        placeholder="Select your Nationality"
        options={[
          "American",
          "Canadian",
          "British",
          "Australian",
          "French",
          "German",
          "Italian",
          "Spanish",
          "Chinese",
          "Japanese",
          "Indian",
          "Brazilian",
          "Mexican",
          "Russian",
          "Egyptian",
          "South African",
          "Saudi Arabian",
          "Turkish",
          "Nigerian",
          "Kenyan",
        ]}
      />
      <Form.TextController
        placeholder="Mobile"
        selectPlaceholder="Code"
        register={register}
        registername="Code"
        options={[
          "Egypt (+20)",
          "United States (+1)",
          "United Kingdom (+44)",
          "Canada (+1)",
          "Australia (+61)",
          "France (+33)",
          "Germany (+49)",
          "Italy (+39)",
          "Spain (+34)",
          "China (+86)",
          "Japan (+81)",
          "India (+91)",
          "Brazil (+55)",
          "Mexico (+52)",
          "Russia (+7)",
          "South Africa (+27)",
          "Saudi Arabia (+966)",
          "Turkey (+90)",
          "Nigeria (+234)",
          "Kenya (+254)",
        ]}
      />
      <div className={style.formSubContainer}>
        <Form.DateTimeController label="Start date" />
        <Form.DateTimeController label="End date" />
      </div>

      <div
        style={{
          border: "1px solid var(--second-color)",
          borderWidth: "1px 0px",
          padding: "15px 0px",
          margin: "15px 0px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Form.CounterController
          initialValue={2}
          label={"No. of Adults"}
          helperText={"( + 12 years )"}
          minValue={1}
        />
        <Form.CounterController
          initialValue={0}
          label={"No. of Children"}
          helperText={"( 2 to 11 years )"}
          minValue={0}
        />
        <Form.CounterController
          initialValue={0}
          label={"No. of Infants"}
          helperText={"( 0 to 2 years )"}
          minValue={0}
        />
      </div>
      <Form.TextareaController
        register={register}
        registername="content"
        placeholder="Please advise your tour requirements"
      />
      <Form.ButtonController>Submit</Form.ButtonController>
    </Form>
  );
};

const OverviewCard = ({ icon, text, subText, details }) => {
  return (
    <div className={style.cardContainer}>
      <div className={style.icon}>{icon}</div>
      <p>{text}</p>
      <h3>{subText}</h3>
      <p>{details}</p>
    </div>
  );
};

const ReviewsSection = ({ reviews }) => {
  const [expandReviews, setExpandReviews] = useState(false);
  return (
    <div className={style.reviewsContainer}>
      <div>
        <div className={style.mainReview}>
          <h1>5</h1>
          <div className={style.ratingStart} title="Rated 5 out of 5">
            <span style={{ width: "100%" }}></span>
          </div>
          <p>(6 Reviews)</p>
        </div>
        <div>
          <RatePrecentage
            totalReviews={6}
            reviews={{ five: 6, four: 4, three: 2, two: 1, one: 0 }}
          />
        </div>
      </div>
      <div>
        {reviews.slice(0, expandReviews ? reviews.length : 3).map((review) => (
          <ReviewCard review={review} />
        ))}
        <button type="button" onClick={() => setExpandReviews(!expandReviews)}>
          {expandReviews ? "See less" : "See all"}
        </button>
      </div>
    </div>
  );
};

const RatePrecentage = ({ totalReviews, reviews }) => {
  return (
    <div className={style.ratePrecentageContainer}>
      <div>
        <p>5 Stars</p>
        <div className={style.rate}>
          <div
            style={{ width: `${(reviews.five / totalReviews) * 100}%` }}
          ></div>
        </div>
        <p>{reviews.five}</p>
      </div>
      <div>
        <p>4 Stars</p>
        <div className={style.rate}>
          <div
            style={{ width: `${(reviews.four / totalReviews) * 100}%` }}
          ></div>
        </div>
        <p>{reviews.four}</p>
      </div>
      <div>
        <p>3 Stars</p>
        <div className={style.rate}>
          <div
            style={{ width: `${(reviews.three / totalReviews) * 100}%` }}
          ></div>
        </div>
        <p>{reviews.three}</p>
      </div>
      <div>
        <p>2 Stars</p>
        <div className={style.rate}>
          <div
            style={{ width: `${(reviews.two / totalReviews) * 100}%` }}
          ></div>
        </div>
        <p>{reviews.two}</p>
      </div>
      <div>
        <p>1 Stars</p>
        <div className={style.rate}>
          <div
            style={{ width: `${(reviews.one / totalReviews) * 100}%` }}
          ></div>
        </div>
        <p>{reviews.one}</p>
      </div>
    </div>
  );
};

const ReviewCard = ({ review }) => {
  const { rating, title, userName, date, comment } = review;
  return (
    <div className={style.reviewCardContainer}>
      <div className={style.ratingStart} title="Rated 5 out of 5">
        <span style={{ width: rating }}></span>
      </div>
      <h3>{title}</h3>
      <div className={style.userData}>
        <p>{userName}</p>
        <p>{date}</p>
      </div>
      <p>{comment}</p>
    </div>
  );
};
