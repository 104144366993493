import React from 'react'

const DistiItem = ({imgSrc, country, content}) => {
    return (
        <div className="desti-item overlay-desti-item">
            <figure className="desti-image">
                <img src={imgSrc} alt=""/>
            </figure>
            <div className="meta-cat bg-meta-cat">
                <a href="#">{country}</a>
            </div>

            {/* <div className="desti-content">
                <h3>
                    <a href="#">{content}</a>
                </h3>
                <div className="rating-start" title="Rated 5 out of 4">
                    <span style={{width: '53%'}}></span>
                </div>
            </div> */}
        </div>
    )
}

export default DistiItem