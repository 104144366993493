import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { api_url } from '../../constants/base_url';
import axios from 'axios';
import PostThumb from '../postThumb';
import Loading from '../shared/Loading/Loading';


const getSubCards = (data, blogId, language) => {
    // Filter the data to find the correct blog entry by blogId and language
    const filteredBlog = data.find(
        (item) => item.blogId === blogId && item.language === language
    );

    // If the filtered blog exists, extract subcards
    if (filteredBlog) {
        // Object.keys() gets all keys of the filteredBlog object
        // Filter keys that start with 'sub_card_' and map them to their values
        const subCards = Object.keys(filteredBlog)
            .filter((key) => key.startsWith('sub_card_')) // Find all sub_card_X keys
            .map((key) => filteredBlog[key]); // Map to the actual sub_card values

        return subCards;
    } else {
        // If no matching blog is found, return an empty array
        return [];
    }
};


const BlogSubcards = ({ blogId, language }) => {
    const { data, error, isLoading, isError, refetch } = useQuery({
        queryKey: ['blogCard'],
        queryFn: () =>
            axios.get(`${api_url}blogLanguage`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            }),
        onSuccess: (response) => {
            console.log("All Languages:", response.data);
        },
        onError: (err) => {
            console.error("Error fetching data:", err);
        }
    });

    const subCards = getSubCards(data?.data?.data, blogId, language);

    console.log("Sub cards:", subCards);

    return (
        isLoading ? <Loading /> : <ul>
            {subCards.map((item) => (
                <li>
                    <figure class="post-thumb">
                        <a href="#"><img src={`${api_url}${item.image[0]}`.replace("/api/", "/storage/")} alt="" /></a>
                    </figure>
                    <div class="post-content">
                        <h5>{item.title}</h5>
                        <p>{item.content}</p>
                    </div>
                </li>
            ))}
        </ul>
    )
}

export default BlogSubcards